const elements = document.querySelectorAll('.grouped-inputs');

elements.forEach(function (element) {
    const elementInputs = element.querySelectorAll('input, select');

    elementInputs.forEach(function (input) {
        input.addEventListener('focus', function () {
            element.classList.add('focused');
        })

        input.addEventListener('blur', function () {
            element.classList.remove('focused');
        })
    });

});

const frmCitizenship = document.getElementById('frm-citizenship');
// countryChange.forEach(places => console.log(places));

// frmCitizenship.addEventListener('change', function() {
//     const countryChange = document.querySelectorAll('.default-country');
//     var frmCitizenshipSelected = this.value;            
//     console.log('Citizenship Selected: ', frmCitizenshipSelected);
// });

const tncCard = document.querySelectorAll('.card-tnc');

tncCard.forEach(function(tnc) {
    const tncCheckbox = tnc.querySelectorAll('input[type=checkbox]');
    const tncText = tnc.querySelectorAll('.card-body');
    // tncCheckbox.forEach(checkboxes => console.log(checkboxes));
    
    tncCheckbox.forEach(function (item) {
        item.addEventListener('change', function () {
            if (item.checked) {
                console.log('accepted');
            } else {
                console.log('not accepted');
            }
        })
    });
        
});
