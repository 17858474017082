import * as bootstrap from 'bootstrap'

const element = document.querySelectorAll('[data-wizard]');

element.forEach(function (item) {

  // Get the tab elements
  var tabElements = document.querySelectorAll('.nav-tabs .nav-link');
  
  // Get the previous and next buttons
  var prevButton = document.querySelector('.prev-button');
  var nextButton = document.querySelector('.next-button');
  
  // Get the submit button
  var submitButton = document.querySelector('.submit-button');
  
  // Get the progress bar
  var progressBar = document.querySelector('.progress-bar');
    
  // Calculate the initial progress bar width based on the number of tab elements
  var initialWidth = 100 / tabElements.length;
  
  // Set the initial progress bar width
  progressBar.style.width = initialWidth + '%';
  
  // Hide the submit button by default
  submitButton.classList.add('d-none');
  
  function updateElements(activeTabIndex) {
    // Update the previous button
    prevButton.disabled = activeTabIndex === 0;
  
    // Update the next and submit buttons
    if (activeTabIndex === tabElements.length - 1) {
      nextButton.classList.add('d-none');
      submitButton.classList.remove('d-none');
    } else {
      nextButton.classList.remove('d-none');
      submitButton.classList.add('d-none');
    }
  
    // Update the progress bar
    progressBar.style.width = (activeTabIndex + 1) / tabElements.length * 100 + '%';
    progressBar.setAttribute('aria-valuenow', (activeTabIndex + 1) / tabElements.length * 100);
  }
  
  // Call the updateElements function
  updateElements(0); // Initialize the buttons and progress bar with the first tab active

  // Add click event listeners to the buttons
  prevButton.addEventListener('click', function() {
    // Find the currently active tab
    var activeTab = document.querySelector('.nav-tabs .nav-link.active');
    // Get the index of the active tab
    var activeTabIndex = Array.prototype.indexOf.call(tabElements, activeTab);
    // Check if the active tab is the first tab
    if (activeTabIndex > 0) {
      // If not, activate the previous tab
      tabElements[activeTabIndex - 1].click();
    }
    // Update the buttons and progress bar
    updateElements(activeTabIndex - 1);
  });
  
  nextButton.addEventListener('click', function() {
    // Find the currently active tab
    var activeTab = document.querySelector('.nav-tabs .nav-link.active');
    // Get the index of the active tab
    var activeTabIndex = Array.prototype.indexOf.call(tabElements, activeTab);
    // Check if the active tab is the last tab
    if (activeTabIndex < tabElements.length - 1) {
      // If not, activate the next tab
      tabElements[activeTabIndex + 1].click();
    }
    // Update the buttons and progress bar
    updateElements(activeTabIndex + 1);
  });
  
  // Add click event listeners to the tab elements
  tabElements.forEach(function(tabElement) {
    tabElement.addEventListener('click', function() {
      // Get the index of the clicked tab element
      var activeTabIndex = Array.prototype.indexOf.call(tabElements, tabElement);
      // Update the buttons and progress bar
      updateElements(activeTabIndex);
    });
  });
  
  // Add a click event listener to the submit button
  submitButton.addEventListener('click', function() {
    // Show the modal
    var myModal = new bootstrap.Modal(document.getElementById('myModal'), {
      keyboard: false
    })
    myModal.show()
  });

})