const element = document.querySelectorAll('[data-ea-select]');

element.forEach(function (item) {

  const searchForm = document.querySelector('form[role="search"]'); // get the search form element
  const searchInput = searchForm.querySelector('input[type="search"]'); // get the search input element
  const searchButton = searchForm.querySelector('button[type="submit"]'); // get the search button element
  
  const performSearch = () => { // define a function to perform the search
    const searchText = searchInput.value.toLowerCase(); // get the search text from the search input element and convert it to lowercase
    
    const divElements = document.querySelectorAll('.position-relative'); // get all div elements with the .position-relative class
    
    let visibleDivElements = 0; // initialize a variable to keep track of the visible div elements
    
    if (searchText) { // if the search text is not empty
      divElements.forEach(divElement => {
        if (!divElement.innerText.toLowerCase().includes(searchText)) { // if the inner text of the div element (converted to lowercase) does not include the search text
          divElement.classList.add('d-none'); // hide the div element by adding the .d-none class
        } else {
          divElement.classList.remove('d-none'); // show the div element by removing the .d-none class
          visibleDivElements++; // increment the visibleDivElements variable
        }
      });
    } else { // if the search text is empty
      divElements.forEach(divElement => {
        divElement.classList.remove('d-none'); // show the div element by removing the .d-none class
        visibleDivElements++; // increment the visibleDivElements variable
      });
    }
    
    const listGroupCount = document.querySelector('#list-group-count'); // get the list-group-count element
    const listGroupVisible = document.querySelector('#list-group-visible'); // get the list-group-visible element
    listGroupCount.textContent = divElements.length; // set the text content of the list-group-count element to the total number of div elements
    listGroupVisible.textContent = visibleDivElements; // set the text content of the list-group-visible element to the number of visible div elements
    
    const inputElements = document.querySelectorAll('input[type="radio"]'); // get all input elements with the type "radio"
    inputElements.forEach(inputElement => { //
      inputElement.checked = false; // uncheck the input element by setting the checked property to false
    });
  }
  
  searchInput.addEventListener('input', performSearch); // add an input event listener to the search input element
  searchInput.addEventListener('keydown', event => { // add a keydown event listener to the search input element
    if (event.key === 'Backspace') { // if the key pressed is the backspace key
      performSearch(); // call the performSearch function
    }
  });
  searchButton.addEventListener('click', event => { // add a click event listener to the search button
    event.preventDefault(); // prevent the default form submission behavior
    performSearch(); // call the performSearch function
  });

})