import * as bootstrap from 'bootstrap'

document.addEventListener("DOMContentLoaded", function() {
  var myModal = document.getElementById('regsModal')
  var myModalInstance = new bootstrap.Modal(myModal)

  // If the user has not accepted the terms yet, show the modal
  if (!localStorage.getItem('termsAccepted')) {
    myModalInstance.show();
  }

  // When the user clicks the accept button, set a value in localStorage
  document.getElementById('acceptTerms').addEventListener('click', function() {
    localStorage.setItem('termsAccepted', 'true');
    myModalInstance.hide();
  });
});
