import TomSelect from "tom-select";

const tomselects = document.querySelectorAll('[data-tomselect]');

tomselects.forEach(function (item) {
    new TomSelect(item,{
        sortField: {
            field: "text",
            direction: "asc"
        }
    });
});